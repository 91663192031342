const filters = [
  {
    id: 1,
    name: 'room_type',
    options: [
      { name: 'single', default: false, id: 1 },
      { name: 'double', default: true, id: 2 },
      { name: 'multi', default: false, id: 3 },
    ],
  },
  {
    id: 2,
    name: 'breakfast',
    options: [
      { name: 'included', default: false, id: 4 },
      { name: 'not_included', default: true, id: 5 },
    ],
  },
  {
    id: 3,
    name: 'market_rates',
    options: [
      { name: 'lowest', default: true, id: 6 },
      { name: 'highest', default: false, id: 7 },
      { name: 'home', default: true, id: 8 },
    ],
  },
]

export { filters }
