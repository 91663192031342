import axios from 'axios'

import BaseService from './BaseService.js'
import DashboardService from './DashboardService.js'

class PremiumService extends BaseService {
  constructor() {
    super({
      baseUrl: import.meta.env.VITE_PREMIUM_API_URL,
    })
  }

  fetchZuoraAccount() {
    return axios({
      url: `${this.baseUrl}/api/zuora/account`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  sendZuoraAccountCreation({ body, language }) {
    return axios({
      url: `${this.baseUrl}/api/zuora/account`,
      method: 'POST',
      data: body,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
        'X-Trv-Language': language ?? 'en-US',
      },
    })
  }

  sendZuoraAccountUpdate({ body, language }) {
    return axios({
      url: `${this.baseUrl}/api/zuora/account`,
      method: 'PUT',
      data: body,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
        'X-Trv-Language': language ?? 'en-US',
      },
    })
  }

  fetchStudioPlusCampaign({ accommodationId }) {
    return axios({
      url: `${this.baseUrl}/api/premium/${accommodationId}/campaign`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  fetchAvailablePaymentMethods(currencyCode) {
    const params = {
      currencyCode,
    }
    if (window.location.hostname === 'studio.dev.trv.cloud') params.env = 'dev'
    return axios({
      url: `${this.baseUrl}/api/zuora/payment-methods`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
      params,
    })
  }

  fetchExistingPaymentMethods() {
    return axios({
      url: `${this.baseUrl}/api/zuora/account/payment-methods`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  validateTaxId(payload) {
    return axios({
      url: `${this.baseUrl}/api/validation/tax`,
      method: 'POST',
      data: payload,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  fetchStudioPlusCampaignsForOrganisation() {
    return axios({
      url: `${this.baseUrl}/api/premium/campaigns`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  fetchTransactionsHistory(id, productType) {
    return axios({
      url: `${this.baseUrl}/api/zuora/account/invoices?itemId=${id}&productType=${productType}`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  getInvoice(id) {
    return axios({
      url: `${this.baseUrl}/api/zuora/account/invoices/${id}`,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  fetchStudioPlusPrice({ accommodationId, countryCode, postalCode, countryState, vatId, currency }) {
    let requestUrl = `/api/premium/${accommodationId}/price?currency=${currency}`
    if (!postalCode || !countryCode) {
      requestUrl = `/api/premium/${accommodationId}/price?currency=${currency}`
    } else if (countryState !== null && (vatId === null || vatId === '')) {
      requestUrl = `/api/premium/${accommodationId}/price?currency=${currency}&countryCode=${countryCode}&postalCode=${postalCode}&state=${countryState}`
    } else if (countryState !== null && vatId !== null && vatId.length > 0) {
      requestUrl = `/api/premium/${accommodationId}/price?currency=${currency}&countryCode=${countryCode}&postalCode=${postalCode}&state=${countryState}&vatId=${vatId}`
    } else if (countryState === null && vatId !== null && vatId?.length > 0) {
      requestUrl = `/api/premium/${accommodationId}/price?currency=${currency}&countryCode=${countryCode}&postalCode=${postalCode}&vatId=${vatId}`
    } else if (countryState === null && (vatId === null || vatId === '')) {
      requestUrl = `/api/premium/${accommodationId}/price?currency=${currency}&countryCode=${countryCode}&postalCode=${postalCode}`
    }

    return axios({
      url: `${this.baseUrl}${requestUrl}`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  sendStudioPlusSubscription({ accommodationId, body }) {
    return axios({
      url: `${this.baseUrl}/api/premium/${accommodationId}/campaign`,
      method: 'POST',
      data: body,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  cancelSubscription({ accommodationId, body }) {
    return axios({
      url: `${this.baseUrl}/api/premium/${accommodationId}/deactivate`,
      method: 'PUT',
      data: body,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  getPaidSubscriptions(accommodationId) {
    if (!accommodationId)
      throw new Error('Error in GET /api/premium/admin/${accommodationId}/campaigns: No accommodationId provided')
    return axios({
      url: `${this.baseUrl}/api/premium/admin/${accommodationId}/campaigns`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  sendErrorMetrics(body) {
    return axios({
      url: `${this.baseUrl}/api/zuora/error/log`,
      method: 'POST',
      data: body,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  newRateConnectCampaign({ accommodationId, body }) {
    return axios({
      url: `${this.baseUrl}/api/rateconnect/${accommodationId}/campaign`,
      method: 'POST',
      data: body,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  resumeRateConnectCampaign({ accommodationId }) {
    return axios({
      url: `${this.baseUrl}/api/rateconnect/${accommodationId}/campaign/resume`,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  fetchBookingLog({ accommodationId, filtrationDate, currencyCode, startDate, endDate, marketCodes }) {
    if (!this.studioToken)
      throw new Error('Error in GET /api/rateconnect/${accommodationId}/booking_log: No studioToken provided')

    return axios({
      url: `${this.baseUrl}/api/rateconnect/${accommodationId}/booking-log?filtrationDate=${filtrationDate}&currencyCode=${currencyCode}&startDate=${startDate}&endDate=${endDate}&marketCodes=${marketCodes}`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  fetchMarkets({ accommodationId, partnerId }) {
    if (!this.studioToken)
      throw new Error(
        'Error in GET api/rateconnect/${accommodationId}/partner/${partnerId}/markets: No studioToken provided'
      )
    return axios({
      url: `${this.baseUrl}/api/rateconnect/${accommodationId}/partners/${partnerId}/markets`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  getUserCurrency() {
    return axios({
      url: `${this.baseUrl}/locale/user`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  getGuestCurrency() {
    return axios({
      url: `${this.baseUrl}/locale/guest`,
      method: 'GET',
    })
  }

  fetchPartners(accommodationId) {
    return axios({
      url: `${this.baseUrl}/api/rateconnect/${accommodationId}/partners`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    }).then(async response => {
      const partnerIds = []
      response.data.map(item => partnerIds.push(item.partnerId))

      const partnersData = await DashboardService.fetchPartnersName(partnerIds)
      return response.data.map(item => {
        let name
        partnersData?.data?.data?.getAdvertiserDetails?.advertiserDetails?.forEach(partner => {
          if (partner.nsid.id === item.partnerId) {
            name = partner.originalName.value
          }
        })
        return {
          canNetCpa: item.canNetCpa,
          partnerId: item.partnerId,
          partnerStatus: item.partnerStatus,
          connectionStatus: item.connectionStatus,
          partnerName: name ?? item.internalName,
        }
      })
    })
  }
}

export default new PremiumService()
