<template>
  <div class="tw-relative">
    <label class="tw-font-bold tw-text-sm" for="competitors-search">{{ $t('onboarding_search_title') }}</label>
    <div
      class="tw-flex tw-items-center tw-border tw-border-gray-500 tw-rounded-lg tw-py-[9px] tw-px-3 tw-gap-2 tw-font-base"
      :class="selectedValues.length >= 5 ? 'tw-bg-gray-200' : 'tw-bg-white'"
    >
      <input
        data-hj-allow
        id="competitors-search"
        type="search"
        :disabled="selectedValues.length >= 5"
        class="focus:tw-outline-none tw-grow"
        :placeholder="$t('tbs_registration_search_field_example')"
        :value="searchValue"
        @focus="showResultsOnFocus"
        @input="setSearchValue"
        @blur="isInputFocused = false"
      />
    </div>
    <SearchResults
      v-if="openResults"
      :data="!isSearchSuggestionsFetching ? searchSuggestions : []"
      :suggestions="suggestions"
      :selectedValues="selectedValues"
      :on-clickaway="hideResults"
      @onSelect="option => onSelect(option)"
      :isSearching="isLoading"
    />
  </div>
</template>
<script setup>
import { ref, watch } from 'vue'
import debounce from 'lodash/debounce'

import SearchResults from '@/components/rateInsights/onboarding/SearchResults.vue'
import RateInsightsService from '@/services/RateInsightsService'

const props = defineProps({
  suggestions: Array,
  selectedValues: Array,
})

const searchValue = ref('')
const openResults = ref(false)
const isInputFocused = ref(false)
const searchSuggestions = ref([])
const isLoading = ref(false)
const emit = defineEmits(['onSelect'])
const onSelect = value => {
  emit('onSelect', value)
  if (props.selectedValues.length >= 4) {
    hideResults()
  }
}

const setSearchValue = event => {
  isLoading.value = true
  searchValue.value = event.target.value
}

const hideResults = () => {
  if (!isInputFocused.value) {
    openResults.value = false
    searchValue.value = ''
  }
}

const showResultsOnFocus = () => {
  isInputFocused.value = true
  if (
    searchValue.value.length > 0 ||
    props.suggestions ||
    (searchSuggestions.value && searchSuggestions?.value !== null && searchSuggestions?.value?.length > 0)
  ) {
    openResults.value = true
  }
}

const fetchSearchSuggestions = async searchValue => {
  if (searchValue.length <= 2) return
  await RateInsightsService.searchForCompetitors(searchValue)
    .then(result => {
      isLoading.value = false
      searchSuggestions.value = result.data
    })
    .catch(() => {
      isLoading.value = false
      searchSuggestions.value = []
    })
}

const debounceSearchValue = debounce(fetchSearchSuggestions, 500)
watch(searchValue, debounceSearchValue)

watch(
  searchSuggestions,
  newValue => {
    if (newValue?.length > 0) {
      openResults.value = true
    }
  },
  { immediate: true }
)
</script>
