<template>
  <BookingTable v-if="tableData" :columns="columns" :data="tableData" :currency="currency" />
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

import BookingTable from '@/components/rateConnect/performance/table/BookingTable.vue'
import { useBookingLog } from '@/components/rateConnect/queries'
import { useRateConnectPerformance } from '@/components/rateConnect/performance/useRateConnectPerformance'
import { selectedAccommodationId } from '@/layouts/queries'

const store = useStore()
const currency = computed(() => store.state.currency)
const filtrationDate = computed(() => 'bookingDate')

const { selectedMarket, formattedStartDate, formattedEndDate } = useRateConnectPerformance()

const { bookingLogData } = useBookingLog(
  selectedAccommodationId,
  filtrationDate,
  currency,
  formattedStartDate,
  formattedEndDate,
  selectedMarket
)

const allColumns = {
  bookingId: {
    header: 'rc_bookings_list_booking_id',
    hint: 'rc_booking_id_tooltip',
    id: 'bookingId',
    colType: 'string',
    sortable: false,
    className: 'col-bookingId',
  },
  bookingDate: {
    header: 'rc_bookings_list_booking_date',
    id: 'bookingDate',
    colType: 'date',
    sortable: true,
    className: 'col-bookingDate',
  },
  status: {
    header: 'rc_bookings_list_status',
    hint: 'rc_status_tooltip',
    id: 'status',
    colType: 'status',
    sortable: true,
    className: 'col-status',
  },
  checkinDate: {
    header: 'rc_bookings_label_arr_date',
    id: 'arrivalDate',
    colType: 'date',
    sortable: true,
    className: 'col-stayDate',
  },
  checkoutDate: {
    header: 'rc_bookings_label_dep_date',
    id: 'departureDate',
    colType: 'date',
    sortable: true,
    className: 'col-stayDate',
  },
  market: {
    header: 'rc_bookings_list_market',
    id: 'market',
    colType: 'flag',
    sortable: true,
    className: 'col-market',
  },
  bookingValue: {
    header: 'rc_bookings_list_booking_value',
    id: 'bookingValue.amountCampaignCurrency',
    colType: 'currency',
    sortable: true,
    className: 'col-bookingValue',
  },
  commissionCost: {
    header: 'rc_bookings_commission',
    hint: 'rc_commissions_tooltip',
    id: 'bookingValue.commissionCost',
    colType: 'currency',
    sortable: true,
    className: 'col-commissionCost',
  },
  avgRoomRate: {
    header: 'rc_bookings_list_arr',
    hint: 'rc_def_arr',
    id: 'arr',
    colType: 'currency',
    sortable: true,
    className: 'col-arr',
  },
  refundAmount: {
    header: 'rc_label_refunded_revenue_pay_per_stay',
    hint: 'rc_refunds_tooltip',
    id: 'refundAmount',
    colType: 'currency',
    sortable: true,
    className: 'col-refundAmount',
  },
  campaignType: {
    header: 'rc_campaign_type_head',
    hint: 'rc_campaign_tooltip',
    id: 'campaignType',
    colType: 'campaignType',
    sortable: true,
    className: 'col-campaignType',
  },
  cancelledDate: {
    header: 'rc_label_cancellation_date_pay_per_stay',
    id: 'cancelledDate',
    colType: 'date',
    sortable: true,
    className: 'col-cancelledDate',
  },
}

const columns = computed(() => {
  if (!bookingLogData.value) return

  // Check type type to display proper columns
  if (bookingLogData.value.every(item => item.campaignType === 'netCpa')) {
    return [
      allColumns.bookingId,
      allColumns.bookingDate,
      allColumns.status,
      allColumns.checkinDate,
      allColumns.checkoutDate,
      allColumns.bookingValue,
      allColumns.commissionCost,
      allColumns.cancelledDate,
      allColumns.refundAmount,
      allColumns.market,
    ]
  } else if (bookingLogData.value.every(item => item.campaignType === 'cpc')) {
    return [
      allColumns.bookingId,
      allColumns.bookingDate,
      allColumns.status,
      allColumns.checkinDate,
      allColumns.checkoutDate,
      allColumns.bookingValue,
      allColumns.market,
    ]
  } else {
    return [
      allColumns.bookingId,
      allColumns.bookingDate,
      allColumns.status,
      allColumns.checkinDate,
      allColumns.checkoutDate,
      allColumns.bookingValue,
      allColumns.commissionCost,
      allColumns.cancelledDate,
      allColumns.refundAmount,
      allColumns.campaignType,
      allColumns.market,
    ]
  }
})

const tableData = computed(() => {
  if (!bookingLogData.value || !Array.isArray(bookingLogData.value)) return null

  return bookingLogData.value.map(
    ({
      requestId,
      marketCode,
      bookingId,
      bookingDate,
      arrivalDate,
      departureDate,
      amountCampaignCurrency,
      bookingAmountLocalCurrency,
      localCurrency,
      confirmed,
      confirmedBy,
      confirmedAt,
      campaignType,
      commissionCost,
      averageAmount,
      cpaLevel,
      status,
      refundAmount: originalRefundAmount,
      cancelledDate: originalCancelledDate,
    }) => {
      const refundAmount = originalRefundAmount ? originalRefundAmount / 100 : undefined
      const cancelledDate = originalCancelledDate || undefined
      return {
        bookingId,
        requestId,
        market: marketCode,
        bookingDate,
        confirmedAt,
        campaignType,
        arrivalDate,
        departureDate,
        bookingValue: {
          amountCampaignCurrency: amountCampaignCurrency / 100,
          amountLocalCurrency: Number(bookingAmountLocalCurrency) / 100,
          localCurrency,
          commissionCost: commissionCost / 100,
        },
        confirmed,
        cpaLevel,
        confirmedByTrivago: confirmedBy === 'trivago',
        arr: averageAmount / 100,
        status,
        refundAmount,
        cancelledDate,
      }
    }
  )
})
</script>
