import { computed } from 'vue'
import { useQuery, useMutation, useQueryClient } from '@tanstack/vue-query'

import PremiumService from '@/services/PremiumService'

const getAvailablePaymentMethods = async ({ currencyCode }) => {
  const response = await PremiumService.fetchAvailablePaymentMethods(currencyCode)
  return response?.data?.paymentMethods
}

export function useAvailablePaymentMethods(selectedAccommodationId, currencyCode) {
  const { isLoading, isFetching, isSuccess, isError, data, refetch } = useQuery({
    queryKey: ['availablePaymentMethods', selectedAccommodationId, currencyCode],
    queryFn: ({ queryKey }) => getAvailablePaymentMethods({ currencyCode: queryKey[2] }),
  })

  const availablePaymentMethodsData = computed(() => data?.value)
  return {
    isLoading,
    isFetching,
    isSuccess,
    isError,
    availablePaymentMethodsData,
    refetch,
  }
}

const getExistingPaymentMethods = async () => {
  const response = await PremiumService.fetchExistingPaymentMethods()
  return response?.data?.paymentMethods
}

export function useExistingPaymentMethods(selectedAccommodationId) {
  const { isLoading, isFetching, isSuccess, isError, data } = useQuery({
    queryKey: ['existingPaymentMethods', selectedAccommodationId],
    queryFn: () => getExistingPaymentMethods(),
  })

  const existingPaymentMethodsData = computed(() => data?.value)

  return {
    existingPaymentMethodsDataLoading: isLoading,
    isFetching,
    isSuccess,
    isError,
    existingPaymentMethodsData,
  }
}

const getZuoraAccountData = async () => {
  const response = await PremiumService.fetchZuoraAccount()
  return response
}

export function useZuoraAccount(token) {
  const { isLoading, isFetching, isSuccess, isError, data, fetchStatus } = useQuery({
    queryKey: ['zuoraAccount', token],
    queryFn: () => getZuoraAccountData(),
    enabled: !!token?.value,
  })

  const isInitialLoading = computed(() => isLoading.value && fetchStatus.value !== 'idle')
  const zuoraAccountData = computed(() => data.value?.data)
  const hasNoBillingInfo = computed(() => data.value?.status === 204)

  return {
    isInitialLoading,
    isLoading,
    isFetching,
    isSuccess,
    isError,
    hasNoBillingInfo,
    zuoraAccountData,
  }
}

export function useStudioPlusCampaign(selectedAccommodationId) {
  const { isLoading, isFetching, isSuccess, isError, data } = useQuery({
    enabled: !!selectedAccommodationId?.value,
    queryKey: ['studioPlusCampaign', selectedAccommodationId],
    queryFn: () => PremiumService.fetchStudioPlusCampaign({ accommodationId: selectedAccommodationId.value }),
  })

  const studioPlusCampaign = computed(() => (data?.value?.data ? data?.value?.data : null))

  const isExpiredSubscription = computed(() => {
    return studioPlusCampaign.value?.endDate ? new Date() > new Date(studioPlusCampaign.value?.endDate) : false
  })

  const isCancelledSubscription = computed(() => {
    return studioPlusCampaign.value?.endDate !== null && !isExpiredSubscription.value
  })

  const isPro = computed(() => studioPlusCampaign.value?.type === 'PRO')
  const isPlus = computed(() => studioPlusCampaign.value?.type === 'PLUS')

  return {
    isLoading,
    isFetching,
    isSuccess,
    isError,
    studioPlusCampaign,
    isExpiredSubscription,
    isCancelledSubscription,
    isPro,
    isPlus,
  }
}

export function useStudioPlusPrice({
  selectedAccommodationId,
  currency,
  countryCode,
  postalCode,
  countryState,
  taxId,
}) {
  const { isLoading, isFetching, isSuccess, isError, data } = useQuery({
    queryKey: ['studioPlusPrice', selectedAccommodationId, countryCode, postalCode, countryState, taxId, currency],
    queryFn: () =>
      PremiumService.fetchStudioPlusPrice({
        accommodationId: selectedAccommodationId.value,
        countryCode: countryCode?.value ?? null,
        postalCode: postalCode?.value ?? null,
        countryState: ['BR', 'US', 'CA', 'MX'].includes(countryCode?.value) ? countryState?.value : null,
        vatId: taxId?.value ?? null,
        currency: currency.value ?? 'EUR',
      }),
  })

  const studioPlusPrice = computed(() => data?.value?.data)

  return {
    isLoading,
    isFetching,
    isSuccess,
    isError,
    studioPlusPrice,
  }
}

export function useZuoraNewAccountMutation() {
  const queryClient = useQueryClient()
  const {
    isLoading: isCreatingNewZuoraAccount,
    isSuccess: isCreatingNewZuoraAccountSuccess,
    isError: isCreatingNewZuoraAccountError,
    mutate: createNewZuoraAccount,
  } = useMutation({
    mutationFn: ({ body, language }) => PremiumService.sendZuoraAccountCreation({ body, language }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['zuoraAccount'] })
      queryClient.invalidateQueries({ queryKey: ['existingPaymentMethods'] })
    },
  })

  return {
    isCreatingNewZuoraAccount,
    isCreatingNewZuoraAccountSuccess,
    isCreatingNewZuoraAccountError,
    createNewZuoraAccount,
  }
}

export function useZuoraUpdateAccountMutation() {
  const queryClient = useQueryClient()
  const {
    isLoading: isUpdatingZuoraAccount,
    isSuccess: isUpdatingZuoraAccountSuccess,
    isError: isUpdatingZuoraAccountError,
    mutate: updateZuoraAccount,
  } = useMutation({
    mutationFn: ({ body, language }) => PremiumService.sendZuoraAccountUpdate({ body, language }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['zuoraAccount'] })
      queryClient.invalidateQueries({ queryKey: ['existingPaymentMethods'] })
    },
  })

  return {
    isUpdatingZuoraAccount,
    isUpdatingZuoraAccountSuccess,
    isUpdatingZuoraAccountError,
    updateZuoraAccount,
  }
}
export function useStudioPlusNewSubscriptionMutation() {
  const {
    isLoading: isSubscribingToStudioPlus,
    isSuccess: isSubscribingToStudioPlusSuccess,
    isError: isSubscribingToStudioPlusError,
    mutate: sendStudioPlusNewSubscription,
  } = useMutation({
    mutationFn: ({ accommodationId, partnerId, itemName, email, language, studioUserId, firstName, lastName, term }) =>
      PremiumService.sendStudioPlusSubscription({
        accommodationId,
        body: { partnerId, itemName, email, language, studioUserId, firstName, lastName, term },
      }),
  })

  return {
    isSubscribingToStudioPlus,
    isSubscribingToStudioPlusSuccess,
    isSubscribingToStudioPlusError,
    sendStudioPlusNewSubscription,
  }
}

export function useCancelStudioPlusMutation() {
  const {
    isLoading: isCancelStudioPlusLoading,
    isSuccess: isCancelStudioPlusSuccess,
    isError: isCancelStudioPlusError,
    mutate: cancelStudioPlus,
  } = useMutation({
    mutationFn: ({
      accommodationId,
      additionalInformation,
      itemName,
      email,
      reason,
      partnerId,
      language,
      studioUserId,
      firstName,
      lastName,
    }) =>
      PremiumService.cancelSubscription({
        accommodationId,
        body: {
          itemName,
          email,
          reason,
          partnerId,
          language,
          studioUserId,
          firstName,
          lastName,
          additionalInformation,
        },
      }),
  })

  return {
    cancelStudioPlus,
    isCancelStudioPlusLoading,
    isCancelStudioPlusSuccess,
    isCancelStudioPlusError,
  }
}

export function useErrorMetrics() {
  const {
    isLoading: sendErrorMetricsLoading,
    isSuccess: sendErrorMetricsSuccess,
    isError: sendErrorMetricsError,
    mutate: sendErrorMetrics,
  } = useMutation({
    mutationFn: ({ message, code, productName, itemId }) =>
      PremiumService.sendErrorMetrics({
        code,
        message,
        productName,
        itemId,
      }),
  })

  return {
    sendErrorMetricsLoading,
    sendErrorMetricsSuccess,
    sendErrorMetricsError,
    sendErrorMetrics,
  }
}

export function useCreateRateConnectCampaign() {
  const {
    isLoading: isCreatingNewRateConnectCampaign,
    isSuccess: isCreatingNewRateConnectCampaignSuccess,
    isError: isCreatingNewRateConnectCampaignError,
    mutate: createNewRateConnectCampaign,
  } = useMutation({
    mutationFn: ({ accommodationId, body }) => PremiumService.newRateConnectCampaign({ accommodationId, body }),
  })

  return {
    isCreatingNewRateConnectCampaign,
    isCreatingNewRateConnectCampaignSuccess,
    isCreatingNewRateConnectCampaignError,
    createNewRateConnectCampaign,
  }
}
