export const CAMPAIGN_MODES = {
  BOTH: 'both',
  CPA: 'cpa',
  CPC: 'cpc',
  NET_CPA: 'netCpa',
  UNKNOWN: 'unknown',
}

export function campaignsAggregateType(campaigns, currentCampaignType) {
  let hasCPC = false
  let hasCPA = false
  let campaignType = currentCampaignType

  campaigns.forEach(periodStats => {
    if (periodStats.campaignTypes.indexOf('cpa') >= 0 || periodStats.campaignTypes.indexOf('netCpa') >= 0) {
      hasCPA = true
    }
    if (periodStats.campaignTypes.indexOf('cpc') >= 0) {
      hasCPC = true
    }
  })

  if (hasCPA && hasCPC) {
    campaignType = CAMPAIGN_MODES.BOTH
  } else if (hasCPC && !hasCPA) {
    campaignType = CAMPAIGN_MODES.CPC
  } else if (hasCPA && !hasCPC) {
    campaignType = CAMPAIGN_MODES.CPA
  }

  return campaignType
}

export function inferCampaignAggregateType(campaign) {
  if (campaign.campaignTypes.every(item => item === 'netCpa')) {
    return CAMPAIGN_MODES.NET_CPA
  } else if (campaign.campaignTypes.every(item => item === 'cpa')) {
    return CAMPAIGN_MODES.CPA
  } else if (campaign.campaignTypes.every(item => item === 'cpc')) {
    return CAMPAIGN_MODES.CPC
  } else {
    return CAMPAIGN_MODES.BOTH
  }
}

export const isCpaCampaign = campaignType =>
  campaignType === CAMPAIGN_MODES.CPA || campaignType === CAMPAIGN_MODES.NET_CPA

function removeItemOnce(arr, value) {
  const index = arr.indexOf(value)
  if (index > -1) {
    arr.splice(index, 1)
  }
  return arr
}

/**
 * @export
 * Gets the available campaigns to select based on eligibleCampaignTypes and payPerStayFeatureFlag
 * @param {object} params
 * @returns
 */
export function getCampaignTypesToSelect(eligibleCampaignTypes = [], payPerStayFeatureFlag = false) {
  const allCampaignTypes = [...eligibleCampaignTypes]
  const availabeCampaignTypes = eligibleCampaignTypes.includes('netCpa')
    ? removeItemOnce(allCampaignTypes, 'cpa')
    : eligibleCampaignTypes

  const campaignTypes = {
    cpc: {
      description: payPerStayFeatureFlag ? 'rc_pay_per_click_setup_flow_description' : 'rc_pay_per_click_description',
      name: 'rc_pay_per_click',
      id: CAMPAIGN_MODES.CPC,
    },
    cpa: {
      description: payPerStayFeatureFlag ? 'rc_pay_per_booking_setup_flow_description' : 'rc_setup_cpa_text_new',
      name: 'rc_pay_per_booking',
      id: CAMPAIGN_MODES.CPA,
    },
  }

  if (payPerStayFeatureFlag) {
    campaignTypes.netCpa = {
      description: 'rc_pay_per_stay_setup_flow_description',
      name: 'rc_pay_per_stay',
      id: CAMPAIGN_MODES.NET_CPA,
    }
  }

  return availabeCampaignTypes.reduce((reducedCampaignTypes, currentEligibleCampaignType) => {
    if (Object.prototype.hasOwnProperty.call(campaignTypes, currentEligibleCampaignType)) {
      reducedCampaignTypes.push(campaignTypes[currentEligibleCampaignType])
    }

    return reducedCampaignTypes
  }, [])
}

export const getBudgetStatus = (budgetRecommendationInfo, inputBudget, budgetConsumed) => {
  if (inputBudget > 0 && inputBudget < budgetConsumed) {
    return 'belowConsumption'
  } else if (inputBudget < budgetRecommendationInfo?.budgetMin) {
    return 'tooLow'
  } else if (inputBudget < budgetRecommendationInfo?.budgetRecommendedMin) {
    return 'low'
  } else if (inputBudget < budgetRecommendationInfo?.budgetRecommendedMax) {
    return 'recommended'
  } else {
    return 'excellent'
  }
}
