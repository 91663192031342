<template>
  <div
    class="tw-w-full tw-flex tw-flex-col tw-flex-wrap md:tw-inline-flex md:tw-flex-row tw-text-sm tw-gap-5 tw-border-b tw-pb-2.5 tw-justify-between tw-items-center"
  >
    <div class="tw-flex tw-gap-3 select-fields-row">
      <AutocompleteField
        id="rate-connect-performance-market-select"
        type="text"
        ariaLabel="rate-connect-performance-market-select"
        class="tw-relative tw-py-2.5 tw-text-base"
        size="big"
        :options="marketOptions"
        :value="$t(selectedMarketValue.name)"
        @onSelect="onSelectedMarketChange"
      />
      <BaseSelect
        :id="'rate-connect-performance-time-select'"
        :display-text-key="'label'"
        :value-key="'value'"
        :options="periodDatesOptions"
        size="big"
        labelRequiresTranslation
        :requireSorting="false"
        :value="selectedPeriod"
        :class="'tw-py-2.5 tw-text-base'"
        @onChange="onSelectedPeriodChange"
      >
      </BaseSelect>
    </div>
    <p class="lg:tw-text-right tw-text-center lg:tw-w-auto tw-w-full tw-text-sm tw-text-gray-800">
      {{ $t('rc_label_last_updated') }}{{ ' ' }}<span>{{ getFormattedDate }}</span>
    </p>
  </div>
</template>
<script setup>
import { useStore } from 'vuex'
import { computed } from 'vue'
import BaseSelect from '@/components/BaseSelect.vue'
import AutocompleteField from '@/components/base/inputs/AutocompleteField.vue'

const store = useStore()
const props = defineProps({
  markets: {
    type: Array,
    default: () => [],
  },
  lastUpdated: {
    type: String,
    default: '',
  },
  selectedPeriod: {
    type: String,
    default: 'last_30_days',
  },
  selectedMarket: {
    type: String,
    default: 'all',
  },
})
const emit = defineEmits(['onSelectedMarketChange', 'onSelectedPeriodChange'])
const onSelectedMarketChange = e => emit('onSelectedMarketChange', e.value)
const onSelectedPeriodChange = e => emit('onSelectedPeriodChange', e)

const periodDatesOptions = [
  {
    value: 'last_14_days',
    label: 'trc_timeframe_last_14_days',
  },
  {
    value: 'current_month',
    label: 'trc_timeframe_this_month',
  },
  { value: 'last_30_days', label: 'last_30_days' },
  { value: 'last_3_months', label: 'last_3_months' },
  { value: 'last_6_months', label: 'last_6_months' },
  { value: 'last_12_months', label: 'last_12_months' },
]
const marketOptions = computed(() => {
  if (!Array.isArray(props.markets)) return [{ value: 'all', name: 'rc_markets_all', withTranslation: true }]
  return [
    { value: 'all', name: 'rc_markets_all', withTranslation: true },
    ...props.markets.map(market => ({
      value: market.marketCode,
      name: 'locale_' + market.marketCode,
      withTranslation: true,
    })),
  ]
})

const selectedMarketValue = computed(() => marketOptions.value.find(item => item.value === props.selectedMarket))
const getFormattedDate = computed(() => {
  return props.lastUpdated
    ? new Date(props.lastUpdated).toLocaleDateString(store.state.locale.language, {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
      })
    : null
})
</script>
<style scoped>
.select-fields-row {
  @media (max-width: theme('screens.md')) {
    width: 100%;
  }
}
.select-fields-row div {
  @media (max-width: theme('screens.md')) {
    width: 49%;
  }
}
.select-fields-row div:first-child {
  @media (max-width: theme('screens.md')) {
    margin-right: 2%;
  }
}
</style>
