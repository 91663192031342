<template>
  <dialog
    ref="modalRef"
    class="tw-z-50 tw-text-center sm:tw-rounded-[20px] tw-m-0 sm:tw-m-auto tw-max-w-full sm:tw-max-w-[revert] tw-max-h-[100dvh] tw-max-h-[100vh] tw-min-h-[100dvh] tw-min-h-[100vh] sm:tw-min-h-[revert] sm:tw-max-h-[revert]"
  >
    <div class="tw-text-right">
      <button class="tw-mb-1" @click="onCloseClick">
        <BaseIcon icon-name="cross" :height="24" :width="24" :viewbox-height="24" :viewbox-width="24">
          <Cross />
        </BaseIcon>
      </button>
    </div>
    <BaseIcon icon-name="trivago Business Studio" height="40" width="126" class="tw-mx-auto tw-mb-6">
      <TrivagoLogo />
    </BaseIcon>
    <div class="sm:tw-px-16">
      <h4
        class="popup-upsell-title tw-font-bold tw-text-2xl tw-mb-5 tw-px-8 sm:tw-px-0"
        v-html="$t('rc_upgrade_header')"
      ></h4>
      <p
        class="popup-upsell-subtext tw-max-w-[340px] tw-mx-auto tw-mb-7 tw-text-sm sm:tw-text-base"
        v-html="$t('rc_upgrade_subtext')"
      ></p>
      <CampaignTypeSelectCardNew class="tw-mb-10 tw-mx-auto" isRecommended isSelected campaignType="netCpa" />
    </div>
    <div
      class="tw-flex tw-flex-col sm:tw-flex-row tw-flex-col-reverse tw-gap-4 sm:tw-justify-between sm:tw-px-4 tw-pb-4"
    >
      <GhostButton @click="onCloseClick" class="tw-hidden sm:tw-block">{{ $t('rc_upgrade_skip') }}</GhostButton>
      <BaseButton @click="onCloseClick" class="tw-text-gray-700 tw-font-bold sm:tw-hidden" size="medium">{{
        $t('rc_upgrade_skip')
      }}</BaseButton>
      <MainButton @click="onUpgradeClick">{{ $t('rc_upgrade_cta') }}</MainButton>
    </div>
  </dialog>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'

import BaseIcon from '@/components/BaseIcon.vue'
import Cross from '@/components/icons/Cross.vue'
import TrivagoLogo from '@/components/icons/TrivagoLogo.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'
import GhostButton from '@/components/base/buttons/GhostButton.vue'
import BaseButton from '@/components/base/buttons/BaseButton.vue'
import CampaignTypeSelectCardNew from '@/components/rateConnect/CampaignTypeSelectCardNew.vue'

const router = useRouter()

const modalRef = ref(null)

onMounted(() => modalRef.value?.showModal())

const onCloseClick = () => {
  window.sessionStorage?.setItem?.('cpa_upsell_skip', true)
  modalRef.value?.close()
}
const onUpgradeClick = () => router.push({ name: 'rate-connect-no-campaign' })
</script>

<style scoped>
.popup-upsell-title :deep(span) {
  @apply tw-text-blue-800;
}
.popup-upsell-subtext :deep(b) {
  @apply tw-font-bold;
}
</style>
