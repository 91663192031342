<template>
  <CustomModal
    :is-modal-open="isOpen"
    @toggleModal="resetModal"
    @onClickAway="resetModal"
    :overlay="'dark'"
    :with-header="true"
    :with-footer="true"
    custom-classes="tw-h-screen md:tw-h-auto tw-w-[100vw] md:tw-max-w-[588px] md:tw-w-[588px] md:tw-rounded-md tw-rounded-none"
  >
    <template #modalHeader>
      <div class="tw-flex tw-items-center">
        <BaseIcon
          v-if="step === 1"
          height="25"
          width="24"
          class="tw-fill-none tw-mr-4 tw-stroke-gray-800 tw-h-[25px] tw-w-[24px]"
        >
          <TriangleWarningColors />
        </BaseIcon>

        <CheckMarkInCircle
          v-if="step === 2 && isPositive"
          height="24px"
          width="24px"
          class="tw-inline tw-mr-4 tw-stroke-gray-700"
        />
        <CloseOutline
          v-if="step === 2 && !isPositive"
          height="24px"
          width="24px"
          class="tw-inline tw-mr-4 tw-stroke-gray-700"
        />
        <h3 class="tw-font-bold tw-text-xl tw-text-gray-700">{{ $t(titleLabel) }}</h3>
      </div>
    </template>

    <div class="tw-flex tw-flex-col tw-w-full">
      <PauseFlowStep1
        v-if="step === 0"
        :reasons="reasons"
        :selectedReasonObject="selectedReasonObj"
        :selectedReason="selectedReason"
        :showHint="hintsVisible"
        :currentCampaignType="currentCampaignType"
        :isItemNetCPAEligible="isItemNetCPAEligible"
        :otherExplanation="otherExplanation"
        @updateOtherExplanation="updateOtherExplanation"
        @selectReason="selectReason"
        @toggleChangeCampaignModal="toggleChangeCampaignModal"
      />
      <PauseFlowStep2 v-if="step === 1" />
      <PauseFlowStep3
        v-if="step === 2"
        :isPositive="isPositive"
        :retries="retries"
        :feedback="feedback"
        @setFeedback="setFeedback"
        :reasonId="selectedReasonObj?.id"
      />
    </div>
    <template #modalFooter>
      <div class="tw-flex tw-justify-end tw-w-full">
        <GhostButton @click="closePopup">{{ $t(cancelButtonLabel) }}</GhostButton>
        <DangerButton v-show="step === 1" @click="nextStep" class="tw-ml-6" :disabled="isNextActionDisabled">
          <span v-show="!isUpdatingCampaignStatus">{{ $t(nextButtonLabel) }}</span>
          <span v-show="isUpdatingCampaignStatus" class="tw-px-4"><RCInlineLoader color="#fff" /></span>
        </DangerButton>
        <MainButton v-show="step !== 1" @click="nextStep" class="tw-ml-6" :disabled="isNextActionDisabled">
          <span v-show="!isUpdatingCampaignStatus">{{ $t(nextButtonLabel) }}</span>
          <span v-show="isUpdatingCampaignStatus" class="tw-px-4"><RCInlineLoader color="#fff" /></span>
        </MainButton>
      </div>
    </template>
  </CustomModal>
</template>
<script setup>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'

import CustomModal from '@/components/base/CustomModal.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import CheckMarkInCircle from '@/components/icons/CheckMarkInCircle.vue'
import TriangleWarningColors from '@/components/icons/TriangleWarningColors.vue'
import CloseOutline from '@/components/icons/CloseOutline.vue'
import GhostButton from '@/components/base/buttons/GhostButton.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'
import DangerButton from '@/components/base/buttons/DangerButton.vue'
import RCInlineLoader from '@/components/rateConnect/RCInlineLoader.vue'
import PauseFlowStep1 from './PauseFlowStep1.vue'
import PauseFlowStep2 from './PauseFlowStep2.vue'
import PauseFlowStep3 from './PauseFlowStep3.vue'
import useToastNotifications from '@/components/notifications/useToastNotifications'
import appAnalytics from '@/utils/tracking'

import { useCampaignsMutation } from '@/components/rateConnect/queries'
import { useStartUp } from '@/components/rateConnect/queries'
import { selectedAccommodationId, useUserDetails } from '@/layouts/queries'
import { getSupportCenterUrl } from '@/utils/Utility.js'

const store = useStore()
const emit = defineEmits(['closeModal', 'toggleChangeCampaignModal'])
const toggleChangeCampaignModal = () => {
  appAnalytics.track(appAnalytics.events.RC_PAUSE_CAMPAIGN_CHANGE_CAMPAIGN_TYPE, {
    reason: selectedReasonObj.value?.value,
    accommodationId: selectedAccommodationId.value,
    campaignType: currentCampaignType.value,
    isNetCPAEligible: isItemNetCPAEligible.value,
  })
  emit('toggleChangeCampaignModal', 'pauseFlow')
  resetModal()
}

defineProps({
  isOpen: Boolean,
})
const step = ref(0)
const hintsVisible = ref(false)
const reasons = [
  {
    group: { value: 'Business operations', label: 'rc_pause_flow_menu_header_1' },
    options: [
      {
        id: 0,
        group: 'Business operations',
        value: 'I don’t need to advertise during the low or off-season',
        label: 'rc_pause_survey_reason_temporary',
      },
      {
        id: 1,
        group: 'Business operations',
        value: 'I’m focusing on other marketing channels',
        label: 'rc_pause_survey_reason_marketing',
      },
      {
        id: 2,
        group: 'Business operations',
        value: 'I’m currently booked up',
        label: 'rc_pause_survey_reason_occupancy',
      },
      {
        id: 3,
        group: 'Business operations',
        value: 'I’m changing connectivity providers',
        label: 'rc_pause_survey_reason_connectivity',
      },
    ],
  },
  {
    group: { value: 'Conversion and traffic', label: 'rc_pause_flow_menu_header_2' },
    options: [
      {
        id: 4,
        group: 'Conversion and traffic',
        value: 'I don’t receive enough bookings',
        label: 'rc_pause_survey_reason_performance',
      },
      {
        id: 5,
        group: 'Conversion and traffic',
        value: 'I don’t find my website rates on trivago',
        label: 'rc_pause_survey_reason_problem',
      },
      {
        id: 6,
        group: 'Conversion and traffic',
        value: 'I don’t receive enough traffic from trivago to my website',
        label: 'rc_pause_survey_reason_traffic',
      },
    ],
  },
  {
    group: { value: 'Clarity and understanding', label: 'rc_pause_flow_menu_header_3' },
    options: [
      {
        id: 7,
        group: 'Clarity and understanding',
        value: 'I don’t fully  understand how Rate Connect works',
        label: 'rc_pause_survey_reason_understanding',
      },
      {
        id: 8,
        group: 'Clarity and understanding',
        value: 'I’m not satisfied with Rate Connect',
        label: 'rc_pause_survey_reason_functionality',
      },
    ],
  },
  {
    group: { value: 'Other reasons', label: 'rc_pause_flow_menu_header_4' },
    options: [
      {
        id: 9,
        group: 'Other reasons',
        value: 'My property has permanently closed ',
        label: 'rc_pause_survey_reason_property',
      },
      {
        id: 10,
        group: 'Other reasons',
        value: 'My property has changed ownership',
        label: 'rc_pause_survey_reason_ownership',
      },
      {
        id: 11,
        group: 'Other reasons',
        value: 'Other',
        label: 'rc_pause_survey_reason_other',
      },
    ],
  },
]
const selectedReason = ref(null)
const selectedReasonObj = ref(null)
const otherExplanation = ref('')
const feedback = ref('')
const isPositive = ref(null)
const retries = ref(0)
const userId = computed(() => store.state.session.userId)
const { userDetails } = useUserDetails(userId)

const setFeedback = text => {
  feedback.value = text
}

const { displayNotification } = useToastNotifications()
const { updateCampaignStatus, isUpdatingCampaignStatus } = useCampaignsMutation()
const { currentCampaignType, isItemNetCPAEligible } = useStartUp(selectedAccommodationId)

const updateOtherExplanation = text => {
  otherExplanation.value = text
}
const selectReason = reason => {
  hintsVisible.value = true
  const reasonGroup = reason.split('-')[0].trim()
  const reasonId = Number(reason.split('-')[1].trim())
  selectedReason.value = reason
  reasons.forEach(reason => {
    if (reason.group.value === reasonGroup) {
      selectedReasonObj.value = reason.options.find(item => item.id === reasonId)
    }
  })
  appAnalytics.track(appAnalytics.events.RC_PAUSE_CAMPAIGN_REASON_SELECTED, {
    reason: selectedReasonObj.value?.value,
    accommodationId: selectedAccommodationId.value,
    campaignType: currentCampaignType.value,
    isNetCPAEligible: isItemNetCPAEligible.value,
  })
}

const isNextActionDisabled = computed(() => {
  if (
    (step.value === 0 && selectedReason.value === null) ||
    (step.value === 0 && hintsVisible.value && selectedReasonObj.value.id === 11 && otherExplanation.value === '') ||
    (step.value === 2 && isPositive.value && feedback.value === '')
  )
    return true
  else return false
})
const titleLabel = computed(() => {
  if (step.value === 0) return 'rc_pause_flow_pause_header'
  if (step.value === 1) return 'rc_pause_flow_reminder_header'
  if (step.value === 2 && isPositive.value) return 'rc_pause_flow_paused_header'
  if (step.value === 2 && !isPositive.value) return 'rc_pause_flow_pause_error_header'
  return ''
})

const cancelButtonLabel = computed(() => {
  if (step.value === 0 || step.value === 1) return 'rc_pause_flow_keep_rates_cta'
  if (step.value === 2 && isPositive.value) return 'rc_pause_flow_feedback_no_cta'
  if (step.value === 2 && !isPositive.value && retries.value <= 2) return 'close'
  if (step.value === 2 && !isPositive.value && retries.value > 2) return 'close'
  return ''
})

const nextButtonLabel = computed(() => {
  if (step.value === 0) return 'rc_pause_flow_continue'
  if (step.value === 1) return 'rc_deactivate_campaign_button'
  if (step.value === 2 && isPositive.value) return 'rc_pause_flow_feedback_send_cta'
  if (step.value === 2 && !isPositive.value && retries.value <= 2) return 'cancellation_fail_retry_cta'
  if (step.value === 2 && !isPositive.value && retries.value > 2) return 'Cancellation_fail_support_cta'
  return ''
})

const supportCenterUrl = computed(() => getSupportCenterUrl(store.state.locale.language, 'contactUs'))
const redirectToContactUs = () => {
  resetModal()
  return window.open(supportCenterUrl.value, '_blank')
}

const nextStep = () => {
  if (step.value === 0 && hintsVisible.value) {
    appAnalytics.track(appAnalytics.events.RC_PAUSE_CAMPAIGN_CONTINUE_BUTTON_CLICKED, {
      reason: selectedReasonObj.value?.value,
      accommodationId: selectedAccommodationId.value,
      campaignType: currentCampaignType.value,
      isNetCPAEligible: isItemNetCPAEligible.value,
    })
    step.value = 1
  } else if (step.value === 1) {
    onPauseCampaign()
  } else if (step.value === 2 && retries.value <= 2 && !isPositive.value) {
    retries.value = retries.value + 1
  } else if (step.value === 2 && retries.value > 2 && !isPositive.value) {
    redirectToContactUs()
  } else if (step.value === 2 && isPositive.value) {
    appAnalytics.track(appAnalytics.events.RC_PAUSE_CAMPAIGN_SEND_FEEDBACK, {
      reason: selectedReasonObj.value?.value,
      accommodationId: selectedAccommodationId.value,
      campaignType: currentCampaignType.value,
      isNetCPAEligible: isItemNetCPAEligible.value,
      feedback: feedback.value,
    })
    resetModal()
  }
}
const closePopup = () => {
  if (step.value === 0 && hintsVisible.value) {
    appAnalytics.track(appAnalytics.events.RC_PAUSE_CAMPAIGN_2_KEEP_RATES_LIVE, {
      reason: selectedReasonObj.value?.value,
      accommodationId: selectedAccommodationId.value,
      campaignType: currentCampaignType.value,
      isNetCPAEligible: isItemNetCPAEligible.value,
    })
  } else if (step.value === 1) {
    appAnalytics.track(appAnalytics.events.RC_PAUSE_CAMPAIGN_3_KEEP_RATES_LIVE, {
      reason: selectedReasonObj.value?.value,
      accommodationId: selectedAccommodationId.value,
      campaignType: currentCampaignType.value,
      isNetCPAEligible: isItemNetCPAEligible.value,
    })
  } else if (step.value === 2 && isPositive.value) {
    appAnalytics.track(appAnalytics.events.RC_PAUSE_CAMPAIGN_NO_FEEDBACK, {
      reason: selectedReasonObj.value?.value,
      accommodationId: selectedAccommodationId.value,
      campaignType: currentCampaignType.value,
      isNetCPAEligible: isItemNetCPAEligible.value,
    })
  }
  resetModal()
}

const resetModal = () => {
  selectedReason.value = null
  selectedReasonObj.value = null
  step.value = 0
  hintsVisible.value = false
  otherExplanation.value = ''
  isPositive.value = null
  retries.value = 0
  feedback.value = ''

  emit('closeModal')
}

const onPauseCampaign = () => {
  appAnalytics.track(appAnalytics.events.RC_PAUSE_CAMPAIGN_IN_FLOW_BUTTON_CLICKED, {
    reasonGroup: selectedReasonObj.value?.group,
    reason: selectedReasonObj.value?.value,
    accommodationId: selectedAccommodationId.value,
    campaignType: currentCampaignType.value,
    isNetCPAEligible: isItemNetCPAEligible.value,
    language: store.state.locale.language,
    email: userDetails.value?.email,
    firstName: userDetails.value?.firstName,
    lastName: userDetails.value?.lastName,
  })
  updateCampaignStatus(
    {
      accommodationId: selectedAccommodationId.value,
      body: { overall: { status: 'inactive' } },
    },
    {
      onSuccess: () => {
        step.value = 2
        isPositive.value = true
        displayNotification({
          message: 'rc_deactivate_campaign_success_message',
          isTranslationKey: true,
          type: 'success',
        })
      },
      onError: () => {
        isPositive.value = false
        displayNotification({
          message: 'save_error',
          isTranslationKey: true,
          type: 'error',
        })
      },
    }
  )
}
</script>
