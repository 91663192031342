import * as Sentry from '@sentry/vue'

import apis from '@/services'
import OrganisationManagementService from '@/services/OrganisationManagementService'
import { getPayloadFromJwt, getTokenFromLocalStorage, isTokenValid, setStudioTokenInServices } from '@/utils/tokenUtils'
import { AuthStateValidationError, GetOrganisationListApiError, UserNotFoundError } from './exceptions'
import { queryClient } from '@/main'
import { fetchOrganisationAssignments, selectedAccommodationId, setSelectedAccommodationId } from '@/layouts/queries'

async function restoreStudioSession({ state, commit, dispatch }, payload) {
  commit('SET_LOADING')

  try {
    if (!isTokenValid(state.JLoToken)) {
      await dispatch('restoreJLoToken')
      if (!state.JLoToken) {
        if (payload.to && payload.to.path !== '/') {
          dispatch('logout', payload.to.path)
        } else {
          dispatch('logout')
        }
        return
      }
    }
    OrganisationManagementService.setJLoToken(state.JLoToken?.access_token)
    if (state.organisations.length === 0) {
      await dispatch('getOrganisationsList')
      commit('SET_IS_ONBOARDING_USER_DETAILS', false)
      dispatch('selectInitialSelectedOrganisation')
    }
    await dispatch('restoreStudioToken')
    if (!state.studioToken) throw new Error('studioToken missing in state')
    const orgAssignments = await dispatch('getAssignmentsList')
    if (!Array.isArray(orgAssignments) || orgAssignments.length === 0) {
      commit('SET_IS_ONBOARDING_ASSIGNMENTS', true)
      setSelectedAccommodationId(null)
      commit('SET_SUCCESS')
      if (!payload?.to && this.$router.currentRoute.value.name !== 'onboarding')
        await this.$router.replace({ name: 'onboarding' })
      else return
    } else {
      commit('SET_IS_ONBOARDING_ASSIGNMENTS', false)
    }
    if (!selectedAccommodationId.value) {
      await dispatch('selectInitialSelectedAccommodationId')
    }
    if (!payload?.to && this.$router.currentRoute.value.name === 'onboarding') await this.$router.replace('/')
  } catch (error) {
    if (error instanceof GetOrganisationListApiError) {
      dispatch('logout')
      return
    }
    if (error instanceof UserNotFoundError) {
      commit('SET_IS_ONBOARDING_USER_DETAILS', true)
      commit('SET_SUCCESS')
      if (!payload?.to && this.$router.currentRoute.value.name === 'onboarding')
        await this.$router.replace({ name: 'onboarding' })
      else return
    } else {
      commit('SET_ERROR')
      Sentry.withScope(scope => {
        scope.setTag('feature', 'restoreStudioSession')
        Sentry.captureException(error)
      })
      return
    }
  }

  commit('SET_SUCCESS')
}

async function restoreJLoToken({ commit }) {
  const tokenFromLocalStorage = getTokenFromLocalStorage('jlo_token')

  if (isTokenValid(tokenFromLocalStorage)) {
    commit('SET_JLO_TOKEN', { token: tokenFromLocalStorage })
    return
  }

  if (tokenFromLocalStorage?.refresh_token) {
    const newToken = await apis.oauth2.requestTokenRefresh(tokenFromLocalStorage.refresh_token)
    commit('SET_JLO_TOKEN', { token: newToken })
  }
}

async function restoreStudioToken({ state, commit, dispatch }) {
  const tokenFromLocalStorage = getTokenFromLocalStorage('studio_token')
  const payload = getPayloadFromJwt(tokenFromLocalStorage?.access_token)

  if (
    payload?.organisation_id &&
    payload.organisation_id === state.selectedOrganisation?.id &&
    isTokenValid(tokenFromLocalStorage)
  ) {
    commit('SET_STUDIO_TOKEN', { token: tokenFromLocalStorage })
    setStudioTokenInServices(tokenFromLocalStorage)
    return
  }

  await dispatch('getStudioToken')
}

async function getStudioToken({ state, commit }) {
  const selectedOrganisationId = state.selectedOrganisation?.id

  const response = await OrganisationManagementService.postGenerateToken({ organisationId: selectedOrganisationId })

  const token = response?.data
  commit('SET_STUDIO_TOKEN', { token })
  setStudioTokenInServices(token)
}

async function authorize(_, { locale = 'en-US', stateParams = {} } = {}) {
  try {
    const authUri = await apis.oauth2.generateAuthUri(locale, stateParams)
    if (authUri) window.location.href = authUri
  } catch {
    apis.oauth2.reset()
  }
}

async function authenticate({ commit, dispatch }) {
  let state, tokenResponse
  try {
    state = apis.oauth2.getAuthorizeResponse(window.location.href)
    tokenResponse = await apis.oauth2.requestToken()
  } catch (error) {
    if (!(error instanceof AuthStateValidationError)) {
      Sentry.withScope(scope => {
        scope.setTag('feature', 'authenticate')
        Sentry.captureException(error)
      })
    }
    dispatch('logout')
    return
  }

  if (!state || !tokenResponse) {
    dispatch('logout')
    return
  }

  commit('SET_JLO_TOKEN', { token: tokenResponse })
  if (state.redirectFrom) {
    window.localStorage.setItem('fromDeepLink', 'true')
  }
  this.$router.replace({ path: state.redirectFrom ? state.redirectFrom : '/' })
}

async function getOrganisationsList({ commit }) {
  try {
    const response = await OrganisationManagementService.getOrganisationsList()
    commit('SET_ORGANISATIONS', { organisations: response?.data?.organisations ?? [] })
  } catch (error) {
    if (error.response?.status === 404) {
      throw new UserNotFoundError()
    } else {
      throw new GetOrganisationListApiError(error)
    }
  }
}

function selectInitialSelectedOrganisation({ state, commit }) {
  const selectedOrgInLocalStorage = window.localStorage.getItem('selected_organisation_id')
  const organisationInState = state.organisations.find(org => org.id === selectedOrgInLocalStorage)
  const selectedOrganisation = organisationInState ?? state.organisations?.[0]
  commit('SET_SELECTED_ORGANISATION', { organisation: selectedOrganisation })
}

async function selectInitialSelectedAccommodationId({ dispatch }) {
  const selectedAccommodationIdInLocalStorage = parseInt(window.localStorage.getItem('selected_accommodation_id'))
  let accommodationIdInAssignments = null
  const assignmentsList = await dispatch('getAssignmentsList')

  if (!isNaN(selectedAccommodationIdInLocalStorage)) {
    accommodationIdInAssignments = assignmentsList?.find(
      assignment => assignment === selectedAccommodationIdInLocalStorage
    )
  }

  if (accommodationIdInAssignments) {
    setSelectedAccommodationId(accommodationIdInAssignments)
  } else {
    setSelectedAccommodationId(assignmentsList?.[0])
  }
}

async function switchOrganisation({ commit, dispatch }, { newOrganisation }) {
  commit('SET_LOADING')
  commit('SET_SELECTED_ORGANISATION', { organisation: newOrganisation })
  await dispatch('selectInitialSelectedAccommodationId')
  await dispatch('restoreStudioSession')
}

async function getAssignmentsList({ state }) {
  const orgId = state.selectedOrganisation.id
  if (!orgId) return

  const orgAssignmentsQueryData = await queryClient.ensureQueryData({
    queryKey: ['organisationAssignments', orgId],
    queryFn: () => fetchOrganisationAssignments(orgId),
  })

  return orgAssignmentsQueryData
}

function logout(_, previousPath) {
  apis.oauth2.reset()
  const logoutUri = apis.oauth2.generateLogoutUri(previousPath)
  if (logoutUri) window.location.href = logoutUri
}

export default {
  authorize,
  restoreStudioSession,
  restoreJLoToken,
  logout,
  getOrganisationsList,
  selectInitialSelectedOrganisation,
  getStudioToken,
  restoreStudioToken,
  authenticate,
  switchOrganisation,
  getAssignmentsList,
  selectInitialSelectedAccommodationId,
}
